.BottomNavigation {
    z-index: 100;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.BottomNavigation-link {
    width: 100%;
    min-width: 60px;
    max-width: 168px;
    flex: 1;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: rgba(0, 0, 0, 0.54);
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
    outline: none;
    position: relative;
}

@media only screen and (max-width: 1170px) {

}

@media only screen and (max-width: 800px) {
    .BottomNavigation {
        flex-wrap: wrap;
    }

    .BottomNavigation-link {
        font-size: 0;
    }
}

@media only screen and (max-width: 480px) {
    .BottomNavigation {
        height: auto !important;
    }

    .BottomNavigation-link {
        font-size: 0;
        min-width: 25%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}