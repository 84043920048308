.ShowMore {
  position: relative;
  margin-bottom: -18px;
  z-index: 1;
}

.ShowMore::before {
  transition: opacity ease 1.8s;
  opacity: 1;
  position: absolute;
  content: '';
  bottom: 30px;
  width: 100%;
  height: 38px;
  background: linear-gradient(to bottom,  rgba(240,249,255,0) 0%,rgba(255,255,255,1) 100%);
}

.ShowMore-button {
  text-align: center;
  margin: 0 auto;
}

.ShowMore-content > p {
  margin: 0 !important;
}

.ShowMore.ShowMore--is-opened::before {
  opacity: 0;
}
