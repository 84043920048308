.dots-progress {
  display: flex;
  align-items: center;
}

.dots-progress-dot {
  display: inline-block;
  margin: 0 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ccc;
  box-shadow: inset 0 0 0 2px rgba(150,150,150,0.1), 0 1px 2px rgba(0,0,0,0.1);
  transition: all 0.4s ease-in-out;
}

.dots-progress-dot:first-child {
  margin-left: 0;
}

.dots-progress-dot.dots-progress-dot-active {
  background: #ff4081;
}

@media only screen and (max-width: 800px) {
  .dots-progress-dot {
    margin: 0 7px;
    width: 21px;
    height: 21px;
  }
}

@media only screen and (max-width: 480px) {
  .dots-progress-dot {
    margin: 0 4px;
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 370px) {
  .dots-progress-dot {
    margin: 0 3px;
    width: 14px;
    height: 14px;
  }
}

@media only screen and (max-width: 300px) {
  .dots-progress-dot {
    margin: 0 2px;
    width: 12px;
    height: 12px;
  }
}