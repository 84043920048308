.Resume {
    padding-bottom: 56px;
}

.Resume .container {
    padding-top: 85px;
    padding-bottom: 69px;
}

.Resume .ScreenBlockContainer:nth-child(odd) .ScreenBlock {
    background: #fff;
}

@media only screen and (max-width: 800px) {
    .Resume .container {
        padding-top: 45px;
        padding-bottom: 45px;
    }
}

@media only screen and (max-width: 480px) {
    .Resume {
        padding-bottom: 88px;
    }
}
